// See more @ https://alpinejs.dev

import focus from '@alpinejs/focus'
//@ts-ignore
import persist from '@alpinejs/persist'
import Alpine from 'alpinejs'

declare const window: any

class AlpineVendor {
    static init: () => void = () => {
        Alpine.plugin(persist)
        Alpine.plugin(focus)
        window.Alpine = Alpine
        Alpine.start()
    }
}

export default AlpineVendor
