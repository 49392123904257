import AlpineVendor from '../vendor/AlpineVendor'
import EchoVendor from '../vendor/EchoVendor'
import SentryVendor from '../vendor/SentryVendor'
import SimpleUploaderVendor from '../vendor/SimpleUploaderVendor'

// Static images loading with Vite
//@ts-ignore
import.meta.glob(['../../images/**'])

// Vendor
EchoVendor.init()
SimpleUploaderVendor.init()
SentryVendor.init()
AlpineVendor.init()
